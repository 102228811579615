import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { IAgreementPostBean } from "../../shared/model/agreement-post-bean.model";
import { ISignedDocument } from "../../shared/model/signed-document.model";

const BASE_URL: string = `${environment.apiUrl}/agreements`

@Injectable({ providedIn: 'root' })
export class AgreementRepository {
    constructor(private http: HttpClient) { }

    public getCurrentAgreementText(documentCode: string): Observable<string> {
        return this.http.get<string>(`${BASE_URL}/current-text?documentCode=${documentCode}`, { responseType: 'text' as 'json' })
    }

    public saveAgreement(agreement: IAgreementPostBean) {
        return this.http.post<string>(`${BASE_URL}`, agreement);
    }

    public getSignedDocument(documentCode: string, recordId: string): Observable<ISignedDocument> {
        return this.http.get<ISignedDocument>(`${BASE_URL}/signed?documentCode=${documentCode}&recordId=${recordId}`);
    }

    public getSignedSupervisorAgreement(): Observable<ISignedDocument> {
        return this.http.get<ISignedDocument>(`${BASE_URL}/signed-supervisor`);
    }
}